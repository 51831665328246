const storageWatcher = {
    async install(app, options) {
        const watches = {}
        const oldValues = {}
        const watcher = {
            on: (name, key, callback) => {
                watches[name] = {}
                watches[name].key = key
                watches[name].callback = callback
                callback(app.ls.get(key))
            },
            off: (name) => {
                delete watches[name]
            }
        }
        setInterval(() => {
            for (let key of Object.keys(watches)) {
                if(oldValues[key] === undefined) {
                    oldValues[key] = {key: key, value: app.ls.get(watches[key].key)};
                    oldValues[key].value = app.ls.get(watches[key].key)
                    watches[key].callback(app.ls.get(watches[key].key))

                }
                if(typeof oldValues[key].value === 'object') {
                    if(JSON.stringify(oldValues[key].value) !== JSON.stringify(app.ls.get(watches[key].key))) {
                        oldValues[key].value = app.ls.get(watches[key].key)
                        watches[key].callback(oldValues[key].value)
                    }
                    return
                }
                if(oldValues[key].value !== app.ls.get(watches[key].key)) {
                    oldValues[key].value = app.ls.get(watches[key].key)
                    watches[key].callback(oldValues[key].value)
                }
            }
        }, 1000)
        app.config.globalProperties.$watcher = watcher
        app.$watcher = watcher
    }
}

export default storageWatcher