import {Toast} from "bootstrap";

export default {
    install: (app) => {
        const w = {
            htmlToElement(html) {
                var template = document.createElement('toast-wrapper');
                html = html.trim(); // Never return a text node of whitespace as the result
                template.innerHTML = html;
                return template.firstChild;
            },
            makeid: (length) => {
                var result = '';
                var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                var charactersLength = characters.length;
                for (var i = 0; i < length; i++) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            },
            show: (object) => {
                const id = w.makeid(12)
                const el = w.htmlToElement(`
                    <div class="mt-4 toast ${object && object.color ? 'bg-'+object.color : 'bg-success'} text-white fade hide" data-bs-autohide="false" id="${id}">
                        <div class="toast-header ${object && object.color ? 'bg-'+object.color : 'bg-success'} text-white">
                            <i class="far fa-gear-alt text-muted me-2"></i>
                            <strong class="me-auto">${object && object.title ? object.title : ''}</strong>
                            <button type="button" class="btn text-white" data-bs-dismiss="toast"><i class="fa fa-xmark"></i></button>
                        </div>
                        <div class="toast-body">
                        ${object && object.message ? object.message : ''}
                        </div>
                    </div>`)
                const wrapper = document.getElementById('toast-wrapper')
                if(wrapper === null) {
                    return
                }
                wrapper.appendChild(el);
                const toast2 = new Toast(document.getElementById(id))
                toast2.show()
                setTimeout(() => {
                    toast2.hide()
                    setTimeout(() => {
                        document.getElementById(id).remove();
                    }, 500)
                }, object.time ? object.time : 5000)
            }
        }
        app.config.globalProperties.$toast = w
        app.$toast = w
    }
}