import WebSocketAsPromised from "websocket-as-promised";

const api = {
    ready: false,
    readyCallbacks: [],
    onReady(callback) {
        if (this.ready)
            callback();
        else
            api.readyCallbacks.push(callback)
    },
    async install(app, options) {
        const api = {
            async init() {
                if (await api.loadMe()) {
                    await api.loadPermissions()
                    app.ls.set('users', JSON.stringify((await api.getUsers())))
                    const config = await api.getGuildConfig()
                    if (config.data)
                        app.ls.set('features', JSON.stringify((await api.getGuildConfig()).data.features))
                    api.channels.initCache()
                    api.user.initCache()
                    api.connectSocket()
                }
            },
            login: async (access) => {
                try {
                    const response = await app.axios.post('/auth/login', {
                        redirect_uri: window.location.origin + window.location.pathname,
                        code: access
                    })
                    if (response.status === 401) {
                        app.$toast.show({
                            color: 'danger',
                            title: 'Keinen Zugriff',
                            message: 'Du hast leider keinen Zugriff auf dieses System'
                        })
                        return false
                    }
                    app.ls.set('access', response.data.access)
                    app.ls.set('expires', response.data.expires)
                    app.ls.set('refresh', response.data.refresh)
                    console.log(app.ls.get('access'))
                    /*await api.loadMe()
                    app.$toast.show({
                        color: 'success',
                        title: 'Login',
                        message: 'Willkommen zurück ' + api.getUsername()
                    })*/
                    return true;
                } catch (e) {
                    return false;
                }
            },
            listGuilds: async () => {
                try {
                    return (await app.axios.get('/auth/guilds')).data
                } catch (e) {
                    return []
                }
            },
            getCurrentGuildName: () => {
                return app.ls.get('guild_name')
            },
            getCurrentGuildId: () => {
                return app.ls.get('guild')
            },
            getFeatures: () => {
                return app.ls.get('features') !== undefined ? JSON.parse(app.ls.get('features')) : []
            },
            loadPermissions: async () => {
                const response = await app.axios.get('/auth/' + app.ls.get('guild') + '/permissions')
                if (!response.data)
                    return
                app.ls.set('permissions', JSON.stringify(response.data.permissions))
                app.ls.set("highestgroup", JSON.stringify(response.data.highest))
                app.ls.set('owner', JSON.stringify(response.data.owner))
            },
            loadMe: async () => {
                try {
                    const response = await app.axios.get('/auth/me')
                    if (response !== undefined && response.code === 'ERR_NETWORK') {
                        app.config.globalProperties.$router.push('/maintenance')
                        return false
                    }
                    if (response === undefined || response.status !== 200) {
                        localStorage.clear()
                        app.config.globalProperties.$router.push('/')
                        return
                    }
                    app.ls.set('avatar', response.data.avatar)
                    app.ls.set('id', response.data.id)
                    app.ls.set('username', response.data.username)
                    return true
                } catch (e) {
                    console.log("konnte nicht laden")
                    return false
                }
            },
            getHighestGroup: () => {
                return app.ls.get('highestgroup') !== null ? JSON.parse(app.ls.get('highestgroup')) : {}
            },
            getUsername: () => {
                return app.ls.get('username')
            },
            getId: () => {
                return app.ls.get('id')
            },
            isOwner: () => {
                const owner = app.ls.get('owner')
                return owner !== null ? owner : false;
            },
            getAvatar: () => {
                return app.ls.get('avatar')
            },
            getCurrentUsers: async () => {
                const response = await app.axios.get('/' + app.ls.get('guild') + '/stats/user/current')
                return response.data
            },
            getCurrentChannels: async () => {
                const response = await app.axios.get('/' + app.ls.get('guild') + '/stats/channels/current')
                return response.data
            },
            getCurrentStaff: async () => {
                const response = await app.axios.get('/' + app.ls.get('guild') + '/stats/staff/current')
                return response.data
            },
            getUsers: async () => {
                const response = await app.axios.get('/' + app.ls.get('guild') + '/member')
                return response.data
            },
            getUserCharts: async () => {
                const response = await app.axios.get('/' + app.ls.get('guild') + '/stats/user')
                return response.data
            },
            hasPermission: (permission) => {
                let has = false
                const perms = JSON.parse(app.ls.get('permissions'))
                for (let perm of perms) {
                    let regex = new RegExp(perm.toString().replaceAll('*', '(.*)'));
                    if (regex.test(permission)) {
                        has = true
                        break
                    }
                }
                return has
            },
            checkPermissions: (check, perms) => {
                let has = false
                for (let perm of perms) {
                    let regex = new RegExp(perm.toString().replaceAll('*', '(.*)'));
                    if (regex.test(check)) {
                        has = true
                        break
                    }
                }
                return has
            },
            getAllMember: async () => {
                const response = await app.axios.get('/' + app.ls.get('guild') + '/member')
                return response.data
            },
            getSpecificUser: async (userId) => {
                const response = await app.axios.get('/' + app.ls.get('guild') + '/member/' + userId)
                return response.data
            },
            removeRoleFromMember: async (userId, roleId) => {
                const response = await app.axios.delete('/' + app.ls.get('guild') + '/member/' + userId + '/roles/' + roleId)
                return response.data
            },
            addRoleToMember: async (userId, roleId) => {
                const response = await app.axios.put('/' + app.ls.get('guild') + '/member/' + userId + '/roles', {roleId})
                return response.data
            },
            listRoles: async () => {
                const response = await app.axios.get('/' + app.ls.get('guild') + '/role')
                return response.data
            },
            listAvailableRoles: async () => {
                const response = await app.axios.get('/' + app.ls.get('guild') + '/role/available')
                return response.data
            },
            getUserActivityScore: async (memberId) => {
                const response = await app.axios.get('/' + app.ls.get('guild') + '/member/' + memberId + '/score')
                return response.data
            },
            getLastMessagesFromUser: async (memberId, count) => {
                const response = await app.axios.post('/' + app.ls.get('guild') + '/message/' + memberId, {limit: count})
                return response.data
            },
            getTeamMemberDetails: async (memberId) => {
                const response = await app.axios.get('/' + app.ls.get('guild') + '/member/' + memberId + '/team-details')
                return response.data
            },
            listTeamMember: async () => {
                const response = await app.axios.get('/' + app.ls.get('guild') + '/team')
                return response.data
            },
            createLogEntry: async (page) => {
                if (app.ls.get('access') === null || app.ls.get('guild') === null)
                    return
                try {
                    await app.axios.post('/' + app.ls.get('guild') + '/log', {page})
                } catch (e) {
                }
            },
            listGroups: async () => {
                const response = await app.axios.get('/' + app.ls.get('guild') + '/group')
                return response.data
            },
            getGroup: async (groupId) => {
                const response = await app.axios.get('/' + app.ls.get('guild') + '/group/' + groupId)
                return response.data
            },
            createGroups: async (name, level) => {
                const response = await app.axios.put('/' + app.ls.get('guild') + '/group', {name, level})
                return response.data
            },
            getPublicPermissions: async () => {
                const response = await app.axios.get('permission')
                return response.data
            },
            addPermissionToGroup: async (groupId, permissions) => {
                const response = await app.axios.post('/' + app.ls.get('guild') + '/group/' + groupId + '/permission', {permissions})
                return response.data
            },
            addRoleToGroup: async (groupId, roleId) => {
                const response = await app.axios.post('/' + app.ls.get('guild') + '/group/' + groupId + '/roles/' + roleId)
                return response.data
            },
            removePermissionFromGroup: async (groupId, permissions) => {
                return await app.axios.delete('/' + app.ls.get('guild') + '/group/' + groupId + '/permission', {data: {permissions}})
            },
            disconnectMember: async (memberId) => {
                return await app.axios.post('/' + app.ls.get('guild') + '/member/' + memberId + '/disconnect')
            },
            listChannels: async () => {
                return await app.axios.get('/' + app.ls.get('guild') + '/channel')
            },
            kickUser: async (memberId) => {
                return await app.axios.post('/' + app.ls.get('guild') + '/member/' + memberId + '/kick')
            },
            timeoutUser: async (memberId, amount, unit) => {
                return await app.axios.post('/' + app.ls.get('guild') + '/member/' + memberId + '/timeout', {
                    for: amount,
                    unit
                })
            },
            removeTimeout: async (memberId) => {
                return await app.axios.delete('/' + app.ls.get('guild') + '/member/' + memberId + '/timeout')
            },
            getGuildConfig: async () => {
                return await app.axios.get('/' + app.ls.get('guild') + '/guild/config')
            },
            updateGuildSecurityConfig: async (securityConfig) => {
                return await app.axios.post('/' + app.ls.get('guild') + '/guild/config/security', securityConfig)
            },
            updateGuildUnbanConfig: async (unbanConfig) => {
                return await app.axios.post('/' + app.ls.get('guild') + '/guild/config/unban', unbanConfig)
            },
            updateGuildMusicConfig: async (unbanConfig) => {
                return await app.axios.post('/' + app.ls.get('guild') + '/guild/config/music', unbanConfig)
            },
            async addBotToDashboard(id) {
                return await app.axios.put('/' + app.ls.get('guild') + '/guild/config/music/add', {id})
            },
            retrieveBanlist: async () => {
                return await app.axios.get('/' + app.ls.get('guild') + '/guild/bans')
            },
            listLinkedDiscords: async () => {
                return await app.axios.get('/' + app.ls.get('guild') + '/guild/linked')
            },
            listAvailableMusicBots: async () => {
                return await app.axios.get('/music/bots')
            },
            getDashboardMessages: async () => {
                return await app.axios.get('/' + app.ls.get('guild') + '/message/dashboard')
            },
            listWarns: async () => {
                return await app.axios.get('/' + app.ls.get('guild') + '/warn')
            },
            deleteWarn: async (warnId) => {
                return await app.axios.delete('/' + app.ls.get('guild') + '/warn/' + warnId)
            },
            findUserOutsideGuild: async (userId) => {
                return await app.axios.get('/' + app.ls.get('guild') + '/member/offline/' + userId)
            },
            getUserCache: () => {
                return app.ls.get('user_cache') !== undefined ? app.ls.get('user_cache') : []
            },
            addUserToCache: (user) => {
                const cache = api.getUserCache()
                cache.push(user)
                app.ls.set('user_cache', cache)
            },
            getChannelDetails: async (channelId) => {
                return await app.axios.get('/' + app.ls.get('guild') + '/channel/' + channelId)
            },
            getChannelHistory: async (channelId) => {
                return await app.axios.get('/' + app.ls.get('guild') + '/channel/' + channelId + '/messages')
            },
            listEmojis: async () => {
                return await app.axios.get('/' + app.ls.get('guild') + '/guild/emojis')
            },
            listLogs: async (filter) => {
                return await app.axios.post('/' + app.ls.get('guild') + '/log/list', filter)
            },
            connectSocket: () => {
                let ws = new WebSocketAsPromised('wss://wss.guildguardian.de/' + btoa(app.ls.get('access')) + '/' + app.ls.get('guild'), {
                    packMessage: data => JSON.stringify(data),
                    unpackMessage: data => JSON.parse(data),
                    attachRequestId: (data, requestId) => Object.assign({id: requestId}, data),
                    extractRequestId: data => data && data.id
                });
                ws.open()
                ws.onMessage.addListener((m) => {
                    const msg = JSON.parse(m)
                    console.log(msg)
                    if (msg.action && msg.action === 'remove') {
                        if (msg.type === 'member') {
                            const cache = api.user.getCache()
                            cache.splice(cache.findIndex(u => u.id === msg.member), 1)
                            app.ls.set('user_cache', cache)
                        }
                    }
                    if (msg.action && msg.action === 'add') {
                        if (msg.type === 'member') {
                            const cache = api.user.getCache()
                            console.log(cache)
                            cache.push(msg.member)
                            app.ls.set('user_cache', cache)
                            console.log(cache)
                        }
                    }
                })
                ws.onClose.addListener(() => {
                    setTimeout(() => {
                        api.connectSocket()
                    }, 5000)
                })
            },
            level: {
                getConfig: async () => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/guild/level')
                },
                enable: async () => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/guild/level/enable')
                },
                disable: async () => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/guild/level/disable')
                },
                getTop5: async () => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/guild/level/top')
                },
                setNotificationChannel: async (channelId) => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/guild/level/notification', {channelId})
                },
                createCustom: async (level, xp, message, role, permanent) => {
                    return await app.axios.put('/' + app.ls.get('guild') + '/guild/level/custom', {
                        level,
                        xp,
                        message,
                        role,
                        permanent
                    })
                },
                modifyCustom: async (level, xp, message, role, permanent) => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/guild/level/custom/' + level, {
                        role,
                        message,
                        xp,
                        permanent
                    })
                },
                deleteCustom: async (level) => {
                    return await app.axios.delete('/' + app.ls.get('guild') + '/guild/level/custom/' + level)
                }
            },
            twitch: {
                getConfig: async () => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/guild/twitch')
                },
                enable: async () => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/guild/twitch/enable')
                },
                disable: async () => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/guild/twitch/disable')
                },
                search: async (search) => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/guild/twitch/search/' + search)
                },
                add: async (id) => {
                    return await app.axios.put('/' + app.ls.get('guild') + '/guild/twitch/add/' + id)
                },
                modify: async (channel, message) => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/guild/twitch', {
                        message,
                        channelId: channel
                    })
                },
                remove: async (id) => {
                    return await app.axios.delete('/' + app.ls.get('guild') + '/guild/twitch/remove/' + id)
                }
            },
            youtube: {
                getConfig: async () => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/guild/youtube')
                },
                enable: async () => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/guild/youtube/enable')
                },
                disable: async () => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/guild/youtube/disable')
                },
                search: async (search) => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/guild/youtube/search/' + search)
                },
                add: async (id) => {
                    return await app.axios.put('/' + app.ls.get('guild') + '/guild/youtube/add/' + id)
                },
                modify: async (config) => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/guild/youtube/', config)
                },
                remove: async (id) => {
                    return await app.axios.delete('/' + app.ls.get('guild') + '/guild/youtube/remove/' + id)
                }
            },
            autoRole: {
                getConfig: async () => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/guild/autorole')
                },
                enable: async () => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/guild/autorole/enable')
                },
                disable: async () => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/guild/autorole/disable')
                },
                add: async (roleId, roles) => {
                    return await app.axios.put('/' + app.ls.get('guild') + '/guild/autorole/add', {
                        roleId,
                        roles
                    })
                },
                addRoleToAutoRole: async (roleId, addRoleId) => {
                    return await app.axios.put('/' + app.ls.get('guild') + '/guild/autorole/' + roleId + '/add/' + addRoleId)
                },
                remove: async (roleId) => {
                    return await app.axios.delete('/' + app.ls.get('guild') + '/guild/autorole/' + roleId)
                },
                removeRoleFromAutoRole: async (roleId, removeRoleId) => {
                    return await app.axios.delete('/' + app.ls.get('guild') + '/guild/autorole/' + roleId + '/remove/' + removeRoleId)
                }
            },
            autoMessage: {
                getConfig: async () => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/guild/automessage')
                },
                enable: async () => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/guild/automessage/enable')
                },
                disable: async () => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/guild/automessage/disable')
                },
                modify: async (config) => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/guild/automessage', config)
                }
            },
            verify: {
                getConfig: async () => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/feature/verify')
                },
                enable: async () => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/feature/verify/enable')
                },
                disable: async () => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/feature/verify/disable')
                },
                modify: async (config) => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/feature/verify', config)
                },
                sendMessage: async () => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/feature/verify/send')
                },
            },
            ticket: {
                getConfig: async () => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/guild/ticket')
                },
                enable: async () => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/guild/ticket/enable')
                },
                disable: async () => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/guild/ticket/disable')
                },
                list: async () => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/guild/ticket/list')
                },
                modify: async (config) => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/guild/ticket', config)
                },
                sendEmbed: async () => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/guild/ticket/send')
                },
                addTeamRole: async (roles) => {
                    return await app.axios.put('/' + app.ls.get('guild') + '/guild/ticket/role/team', {roles})
                },
                removeTeamRole: async (roleId) => {
                    return await app.axios.delete('/' + app.ls.get('guild') + '/guild/ticket/role/team/remove/' + roleId)
                },
                addNotifyRole: async (roles) => {
                    return await app.axios.put('/' + app.ls.get('guild') + '/guild/ticket/role/notify', {roles})
                },
                removeNotifyRole: async (roleId) => {
                    return await app.axios.delete('/' + app.ls.get('guild') + '/guild/ticket/role/notify/remove/' + roleId)
                }
            },
            selfRole: {
                getConfig: async () => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/feature/selfrole')
                },
                enable: async () => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/feature/selfrole/enable')
                },
                disable: async () => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/feature/selfrole/disable')
                },
                getStats: async () => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/feature/selfrole/stats')
                },
                createSelfRole: async (name, message, channelId, onlyOne, buttons) => {
                    return await app.axios.put('/' + app.ls.get('guild') + '/feature/selfrole/add', {
                        name,
                        message,
                        channelId,
                        buttons,
                        onlyOne
                    })
                },
                addButtonToRole: async (roleName, buttonName, roleId) => {
                    return await app.axios.put('/' + app.ls.get('guild') + '/feature/selfrole/' + roleName + '/button/add', {
                        name: buttonName,
                        roleId
                    })
                },
                sendMessage: async (name) => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/feature/selfrole/send', {name})
                },
                remove: async (name) => {
                    return await app.axios.delete('/' + app.ls.get('guild') + '/feature/selfrole/remove/' + name)
                },
                removeButton: async (name, buttonName) => {
                    return await app.axios.delete('/' + app.ls.get('guild') + '/feature/selfrole/' + name + '/button/remove/' + buttonName)
                }
            },
            security: {
                antibot: {
                    getConfig: async () => {
                        return await app.axios.get('/' + app.ls.get('guild') + '/security/antibot')
                    },
                    enable: async () => {
                        return await app.axios.post('/' + app.ls.get('guild') + '/security/antibot/enable')
                    },
                    disable: async () => {
                        return await app.axios.post('/' + app.ls.get('guild') + '/security/antibot/disable')
                    },
                    setNotificationChannel: async (channelId) => {
                        return await app.axios.post('/' + app.ls.get('guild') + '/security/antibot/channel', {channelId})
                    },
                    addRoles: async (roles) => {
                        return await app.axios.put('/' + app.ls.get('guild') + '/security/antibot/roles', {roles})
                    },
                    removeRoles: async (roles) => {
                        return await app.axios.delete('/' + app.ls.get('guild') + '/security/antibot/roles', {data: {roles}})
                    }
                },
                antispam: {
                    getConfig: async () => {
                        return await app.axios.get('/' + app.ls.get('guild') + '/security/antispam')
                    },
                    enable: async () => {
                        return await app.axios.post('/' + app.ls.get('guild') + '/security/antispam/enable')
                    },
                    disable: async () => {
                        return await app.axios.post('/' + app.ls.get('guild') + '/security/antispam/disable')
                    },
                    setNotificationChannel: async (channelId) => {
                        return await app.axios.post('/' + app.ls.get('guild') + '/security/antispam/channel', {channelId})
                    },
                    addRoles: async (roles) => {
                        return await app.axios.put('/' + app.ls.get('guild') + '/security/antispam/roles', {roles})
                    },
                    removeRoles: async (roles) => {
                        return await app.axios.delete('/' + app.ls.get('guild') + '/security/antispam/roles', {data: {roles}})
                    },
                    addChannels: async (channels) => {
                        return await app.axios.put('/' + app.ls.get('guild') + '/security/antispam/channel', {channels})
                    },
                    removeChannel: async (channels) => {
                        return await app.axios.delete('/' + app.ls.get('guild') + '/security/antispam/channel', {data: {channels}})
                    },
                    modify: async (config) => {
                        return await app.axios.post('/' + app.ls.get('guild') + '/security/antispam', config)
                    }
                },
                general: {
                    getConfig: async () => {
                        return await app.axios.get('/' + app.ls.get('guild') + '/security')
                    },
                    enable: async () => {
                        return await app.axios.post('/' + app.ls.get('guild') + '/security/enable')
                    },
                    disable: async () => {
                        return await app.axios.post('/' + app.ls.get('guild') + '/security/disable')
                    },
                    setNotificationChannel: async (channelId) => {
                        return await app.axios.post('/' + app.ls.get('guild') + '/security/channel', {channelId})
                    }
                }
            },
            giveaway: {
                getConfig: async () => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/guild/giveaway')
                },
                enable: async () => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/guild/giveaway/enable')
                },
                disable: async () => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/guild/giveaway/disable')
                },
                create: async (channelId, roleId, message, time, timeType) => {
                    return await app.axios.put('/' + app.ls.get('guild') + '/guild/giveaway/', {
                        channelId,
                        roleId,
                        message,
                        time,
                        timeType
                    })
                },
                list: async () => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/guild/giveaway/list')
                }
            },
            automod: {
                list: async () => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/automod')
                },
                listTemplates: async () => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/automod/template')
                },
                useTemplate: async (templateId) => {
                    return await app.axios.put('/' + app.ls.get('guild') + '/automod/template/use/' + templateId)
                }
            },
            embed: {
                list: async () => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/embed')
                },
                create: async (name) => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/embed', {name})
                },
                modify: async (embedId, config) => {
                    return await app.axios.put('/' + app.ls.get('guild') + '/embed/' + embedId, config)
                },
                send: async (embedId) => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/embed/' + embedId + '/send')
                },
                delete: async (embedId) => {
                    return await app.axios.delete('/' + app.ls.get('guild') + '/embed/' + embedId)
                },
                sendEdit: async (id) => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/embed/' + id + '/edit')
                }
            },
            jointocreate: {
                getConfig: async () => {
                    return app.axios.get('/' + app.ls.get('guild') + '/feature/join-to-create')
                },
                enable: async () => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/feature/join-to-create/enable')
                },
                disable: async () => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/feature/join-to-create/disable')
                },
                addTeamRoles: async (roles) => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/feature/join-to-create/role/add', roles)
                },
                removeTeamRoles: async (roles) => {
                    return await app.axios.delete('/' + app.ls.get('guild') + '/feature/join-to-create/role/remove', {data: roles})
                },
                createStarter: async (name, channelId) => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/feature/join-to-create', {
                        name,
                        channelId
                    })
                },
                removeStarter: async (id) => {
                    return await app.axios.delete('/' + app.ls.get('guild') + '/feature/join-to-create/' + id)
                }
            },
            channels: {
                list: async () => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/channel')
                },
                getCache: async () => {
                    return app.ls.get('channel_cache')
                },
                initCache: async () => {
                    const channels = await api.channels.list()
                    if (channels.status === 200)
                        app.ls.set('channel_cache', channels.data)
                }
            },
            roles: {
                list: async () => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/role')
                },
                listAvailable: async () => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/role/available')
                }
            },
            user: {
                list: async () => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/member')
                },
                ban: async (userId, reason) => {
                    return app.axios.post('/' + app.ls.get('guild') + '/member/' + userId + '/ban', {reason})
                },
                unban: async (userId) => {
                    return app.axios.post('/' + app.ls.get('guild') + '/member/' + userId + '/unban')
                },
                invite: async (userId, groupId) => {
                    return app.axios.post('/' + app.ls.get('guild') + '/member/' + userId + '/invite', {groupId})
                },
                setGroups: async (userId, groups) => {
                    return app.axios.post('/' + app.ls.get('guild') + '/team/' + userId + '/groups', {groups})
                },
                getCache: () => {
                    return app.ls.get('user_cache')
                },
                initCache: async () => {
                    const users = await api.user.list()
                    if (users.status === 200)
                        app.ls.set('user_cache', users.data)
                }
            },
            music: {
                getConfig: async () => {
                    return app.axios.get('/' + app.ls.get('guild') + '/music')
                },
                useTemplate: async (templateId) => {
                    return app.axios.put('/' + app.ls.get('guild') + '/music/add/' + templateId)
                },
                listAvailableBots: async () => {
                    return app.axios.get('/' + app.ls.get('guild') + '/music/bots')
                },
                searchSongs: async (search) => {
                    return app.axios.post('https://music.guildguardian.de/api/music/search', {search})
                }
            },
            apply: {
                getConfig: async () => {
                    return app.axios.get('/' + app.ls.get('guild') + '/feature/apply')
                },
                enable: async () => {
                    return app.axios.post('/' + app.ls.get('guild') + '/feature/apply/enable')
                },
                disable: async () => {
                    return app.axios.post('/' + app.ls.get('guild') + '/feature/apply/disable')
                },
                modify: async (id, config) => {
                    return app.axios.put('/' + app.ls.get('guild') + '/feature/apply/' + id, config)
                },
                create: async (title, message, channelId, categoryId, questions) => {
                    return app.axios.put('/' + app.ls.get('guild') + '/feature/apply', {
                        title,
                        message,
                        channelId,
                        categoryId,
                        questions
                    })
                },
                delete: async (id) => {
                    return app.axios.delete('/' + app.ls.get('guild') + '/feature/apply/' + id)
                },
                enableApply: async (id) => {
                    return app.axios.post('/' + app.ls.get('guild') + '/feature/apply/' + id + '/enable')
                },
                disableApply: async (id) => {
                    return app.axios.post('/' + app.ls.get('guild') + '/feature/apply/' + id + '/disable')
                },
                sendApply: async (id) => {
                    return app.axios.post('/' + app.ls.get('guild') + '/feature/apply/' + id + '/send')
                },
                addQuestion: async (id, question) => {
                    return app.axios.put('/' + app.ls.get('guild') + '/feature/apply/' + id + '/question', {question})
                },
                modifyQuestion: async (id, questionId, question) => {
                    return app.axios.post('/' + app.ls.get('guild') + '/feature/apply/' + id + '/question/' + questionId, {question})
                },
                removeQuestion: async (id, questionId) => {
                    return app.axios.delete('/' + app.ls.get('guild') + '/feature/apply/' + id + '/question/' + questionId)
                },
                addTeamRoles: async (id, roles) => {
                    return app.axios.put('/' + app.ls.get('guild') + '/feature/apply/' + id + '/role/team/add', {roles})
                },
                addNotifyRoles: async (id, roles) => {
                    return app.axios.put('/' + app.ls.get('guild') + '/feature/apply/' + id + '/role/notify/add', {roles})
                },
                addApplyRoles: async (id, roles) => {
                    return app.axios.put('/' + app.ls.get('guild') + '/feature/apply/' + id + '/role/apply', {roles})
                },
                removeTeamRoles: async (id, roles) => {
                    return app.axios.delete('/' + app.ls.get('guild') + '/feature/apply/' + id + '/role/team/remove', {data: {roles}})
                },
                removeNotifyRoles: async (id, roles) => {
                    return app.axios.delete('/' + app.ls.get('guild') + '/feature/apply/' + id + '/role/notify/remove', {data: {roles}})
                },
                removeApplyRoles: async (id, role) => {
                    return app.axios.delete('/' + app.ls.get('guild') + '/feature/apply/' + id + '/role/apply/' + role)
                }
            },
            count: {
                getConfig: async () => {
                    return app.axios.get('/' + app.ls.get('guild') + '/feature/count')
                },
                enable: async () => {
                    return app.axios.post('/' + app.ls.get('guild') + '/feature/count/enable')
                },
                disable: async () => {
                    return app.axios.post('/' + app.ls.get('guild') + '/feature/count/disable')
                },
                setChannel: async (channelId) => {
                    return app.axios.post('/' + app.ls.get('guild') + '/feature/count/channel', {channelId})
                }
            },
            roster: {
                getConfig: async () => {
                    return app.axios.get('/' + app.ls.get('guild') + '/feature/roster')
                },
                enable: async () => {
                    return app.axios.post('/' + app.ls.get('guild') + '/feature/roster/enable')
                },
                disable: async () => {
                    return app.axios.post('/' + app.ls.get('guild') + '/feature/roster/disable')
                },
                create: async (roster) => {
                    return app.axios.put('/' + app.ls.get('guild') + '/feature/roster', roster)
                },
                delete: async (id) => {
                    return app.axios.delete('/' + app.ls.get('guild') + '/feature/roster/' + id)
                },
                modify: async (id, config) => {
                    return app.axios.put('/' + app.ls.get('guild') + '/feature/roster/' + id, config)
                },
                addRole: async (id, roles) => {
                    return app.axios.post('/' + app.ls.get('guild') + '/feature/roster/' + id + '/role', roles)
                },
                removeRole: async (id, role) => {
                    return app.axios.delete('/' + app.ls.get('guild') + '/feature/roster/' + id + '/role/' + role)
                },
                update: async (id) => {
                    return app.axios.post('/' + app.ls.get('guild') + '/feature/roster/' + id + '/update')
                }
            },
            group: {
                list: async () => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/group')
                },
                delete: async (id) => {
                    return await app.axios.delete('/' + app.ls.get('guild') + '/group/' + id)
                }
            },
            unban: {
                getConfig: async () => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/unban')
                },
                enable: async () => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/unban/enable')
                },
                disable: async () => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/unban/disable')
                },
                getConnectedInfo: async () => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/unban/connected')
                },
                getRoles: async () => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/unban/roles')
                },
                addRoles: async (roles) => {
                    return await app.axios.put('/' + app.ls.get('guild') + '/unban/roles', {roles})
                },
                removeRole: async (role) => {
                    return await app.axios.delete('/' + app.ls.get('guild') + '/unban/roles/' + role)
                },
                findServer: async () => {
                    return await app.axios.get('/' + app.ls.get('guild') + '/unban/findserver')
                },
                connect: async (connectId) => {
                    return await app.axios.post('/' + app.ls.get('guild') + '/unban/connect/' + connectId)
                },
                removeConnected: async () => {
                    return await app.axios.delete('/' + app.ls.get('guild') + '/unban/connected')
                },
                apply: {
                    claim: async (applyId) => {
                        return await app.axios.post('/' + app.ls.get('guild') + '/unban/applies/' + applyId + '/claim')
                    },
                    accept: async (applyId) => {
                        return await app.axios.post('/' + app.ls.get('guild') + '/unban/applies/' + applyId + '/accept')
                    },
                    deny: async (applyId) => {
                        return await app.axios.post('/' + app.ls.get('guild') + '/unban/applies/' + applyId + '/deny')
                    },
                    close: async (applyId) => {
                        return await app.axios.post('/' + app.ls.get('guild') + '/unban/applies/' + applyId + '/close')
                    },
                    open: async (applyId) => {
                        return await app.axios.post('/' + app.ls.get('guild') + '/unban/applies/' + applyId + '/open')
                    }
                }
            },
            customBot: {
                getConfig: () => {
                    return app.axios.get('/'+app.ls.get('guild')+'/custombot')
                },
                getBot: () => {
                    return app.axios.get('/'+app.ls.get('guild')+'/custombot/bot')
                },
                create: () => {
                    return app.axios.post('/'+app.ls.get('guild') + '/custombot/create')
                },
                setAvatar: (avatar) => {
                    return app.axios.post('/'+app.ls.get('guild') + '/custombot/avatar', {avatar})
                },
                setBanner: (banner) => {
                    return app.axios.post('/'+app.ls.get('guild') + '/custombot/banner', {banner})
                },
                setName: (name) => {
                    return app.axios.post('/'+app.ls.get('guild') + '/custombot/name', {name})
                }
            },
            guild: {
                getInfo: async () => {
                    return await app.axios.get('/'+app.ls.get('guild') + '/guild/info')
                }
            }
        }
        if (app.ls.get('access') != null) {
            await api.init()
        }

        app.config.globalProperties.$api = api
        app.$api = api
        this.ready = true
        this.runReadyCallbacks()
    },
    runReadyCallbacks() {
        api.readyCallbacks.forEach(callback => callback());
        api.readyCallbacks = [];
    },
    isReady: () => {
        if (api.ready) {
            return Promise.resolve();
        } else {
            return new Promise(resolve => {
                api.onReady(resolve);
            });
        }
    }
}
export default api