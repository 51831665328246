<template>
  <div class="container-fluid position-relative d-flex p-0">

    <Sidebar v-if="!hideSidebar"/>
    <div id="sidebar-content" :class="contentClass">
      <Navbar v-if="!hideNavbar"/>
      <router-view/>

      <!-- Footer Start -->
      <div v-if="!hideFooter" class="container-fluid pt-4 px-4">
        <div class="bg-secondary rounded-top p-4">
          <div class="row">
            <div class="col-12 col-sm-6 text-center text-sm-start">
              &copy; <a href="#">GuildGuardian</a>, All Right Reserved.
            </div>
          </div>
        </div>
      </div>
      <!-- Footer End -->
    </div>

    <!-- Back to Top -->
    <a href="#" class="btn btn-lg btn-primary btn-lg-square back-to-top"><i class="bi bi-arrow-up"></i></a>
  </div>

  <div class="m-2" id="toast-wrapper" style="position: fixed; z-index: 100000; opacity: .9; top: 0; right:0">

  </div>
</template>
<script setup>
import Sidebar from "@/components/Sidebar.vue";
import Navbar from "@/components/Navbar.vue";
import {onBeforeMount, ref, watch} from "vue";
import {useRoute} from "vue-router";

const route = useRoute()

const hideNavbar = ref(false)
const hideSidebar = ref(false)
const hideFooter = ref(false)
const contentClass = ref('content')

// Scroll-Event für das Fenster
window.addEventListener('scroll', function() {
  var scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
  var backToTop = document.querySelectorAll('.back-to-top');

  // Ein- oder Ausblenden basierend auf der Scroll-Position
  if (scrollPosition > 300) {
    backToTop.forEach(function(el) {
      el.style.display = 'block';
      el.style.opacity = 0;
      el.style.transition = 'opacity 0.5s';
      el.style.opacity = 1;
    });
  } else {
    backToTop.forEach(function(el) {
      el.style.opacity = 0;
      el.style.transition = 'opacity 0.5s';
      setTimeout(function() {
        el.style.display = 'none';
      }, 500); // Warten auf das Ausblenden
    });
  }
});

// Klick-Event für '.back-to-top'
document.querySelectorAll('.back-to-top').forEach(function(el) {
  el.addEventListener('click', function(e) {
    e.preventDefault();
    // Sanft zum Anfang der Seite scrollen
    window.scrollTo({top: 0, behavior: 'smooth'});
  });
});


onBeforeMount(() => {
  update()
})

watch(
    () => route.path,
    async newId => {
      update()
    }
)

const update = () => {
  if (route.path === '/login' || route.path === '/tos' || route.path === '/maintenance' || route.path === '/privacy') {
    hideNavbar.value = true
    hideSidebar.value = true
    hideFooter.value = true
    contentClass.value = 'container-fluid'
    return;
  }
  if (route.meta && route.meta.hide) {
    hideNavbar.value = route.meta.hide.navbar
    hideSidebar.value = route.meta.hide.navbar
    hideFooter.value = route.meta.hide.footer
  }else {
    hideNavbar.value = false
    hideSidebar.value = false
    hideFooter.value = false
    contentClass.value = 'content'
  }
}

</script>

<style>
.alert {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  border-radius: 5px;
}
.alert-fixed {
  position:fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index:9999;
  border-radius:0px
}
</style>
