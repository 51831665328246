import {createRouter, createWebHistory} from 'vue-router'
import app from "@/main";

const routes = [
    {path: '/', redirect: '/dashboard'},
    {path: '/dashboard', name: 'Startseite', component: () => import('../views/HomeView.vue')},
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login.vue'),
        meta: {
            hide: {
                sidebar: true,
                navbar: true,
                footer: true
            }
        }
    },
    {
        path: '/tos',
        name: 'Terms of Service',
        component: () => import('@/views/TermsOfService.vue'),
        meta: {
            hide: {
                sidebar: true,
                navbar: true,
                footer: true
            }
        }
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: () => import('@/views/Privacy.vue'),
        meta: {
            hide: {
                sidebar: true,
                navbar: true,
                footer: true
            }
        }
    },
    {
        path: '/maintenance', name: 'Maintenance', component: () => import('@/views/Maintenance.vue'), meta: {
            hide: {
                sidebar: true,
                navbar: true,
                footer: true
            }
        }
    },
    {path: '/group/:groupId', name: 'Group Details', component: () => import('@/views/GroupDetail.vue')},
    {path: '/team-manager', name: 'Team Manager', component: () => import('@/views/TeamManager.vue')},
    {path: '/user', name: 'User', component: () => import('@/views/User.vue')},
    {path: '/user/:userId', name: 'User Details', component: () => import('@/views/UserDetails.vue')},
    {path: '/tickets/support', name: 'Support Tickets', component: () => import('@/views/tickets/SupportTicket.vue')},
    {path: '/embed', name: 'Embeds', component: () => import('@/views/Embed.vue')},
    {path: '/system/log', name: 'Dashboard Log', component: () => import('@/views/system/Log.vue')},
    {path: '/system/banlist', name: 'Bann liste', component: () => import('@/views/system/BanList.vue')},
    {path: '/system/unban', name: 'Unban', component: () => import('@/views/module/Unban.vue')},
    {path: '/system/warns', name: 'Warns', component: () => import('@/views/system/Warn.vue')},
    {path: '/features/level', name: 'Level', component: () => import('@/views/feature/Level.vue')},
    {path: '/features/twitch', name: 'Twitch', component: () => import('@/views/feature/Twitch.vue')},
    {path: '/features/youtube', name: 'YouTube', component: () => import('@/views/feature/YouTube.vue')},
    {path: '/features/autorole', name: 'Autoroles', component: () => import('@/views/feature/AutoRole.vue')},
    {path: '/features/automessage', name: 'AutoMessages', component: () => import('@/views/feature/AutoMessage.vue')},
    {path: '/features/selfrole', name: 'Selfroles', component: () => import('@/views/feature/SelfRole.vue')},
    {
        path: '/features/jointocreate',
        name: 'Join to create',
        component: () => import('@/views/feature/JoinToCreate.vue')
    },
    {path: '/features/apply', name: 'Apply', component: () => import('@/views/feature/Apply.vue')},
    {path: '/features/ticket', name: 'Ticket', component: () => import('@/views/feature/Ticket.vue')},
    {path: '/features/count', name: 'Count', component: () => import('@/views/feature/Count.vue')},
    {path: '/features/roster', name: 'Roster', component: () => import('@/views/feature/Roster.vue')},
    {
        path: '/music', name: 'Musik', component: () => import('@/views/music/index.vue'), children: [
            {path: 'general', name: 'Allgemein', component: () => import('@/views/music/General.vue')},
            {path: 'bots', name: 'Bots', component: () => import('@/views/music/MusicBots.vue')}
        ]
    },
    {path: '/security/general', name: 'Sicherheit', component: () => import('@/views/security/General.vue')},
    {path: '/security/antibot', name: 'Anti Bot', component: () => import('@/views/security/AntiBot.vue')},
    {path: '/security/antispam', name: 'Anti Spam', component: () => import('@/views/security/AntiSpam.vue')},
    {path: '/channel', name: 'Channel', component: () => import('@/views/Channel.vue')},
    {path: '/features/giveaway', name: 'Giveaway', component: () => import('@/views/feature/Giveaway.vue')},
    {path: '/features/verify', name: 'Verify', component: () => import('@/views/feature/Verify.vue')},
    {path: '/system/automod', name: 'AutoMod', component: () => import('@/views/system/AutoMod.vue')},
    {path: '/custombot', name: 'CustomBot', component: () => import('@/views/CustomBot.vue')}
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from) => {
    if (to.path === '/tos')
        return true
    if (app.ls.get('access') == null && to.path !== '/login') {
        return '/login'
    }
    if (app.ls.get('access') != null && to.path === '/login')
        return '/'
    if (app.$api)
        app.$api.createLogEntry(to.fullPath)
    document.title = to.name
    if (window.mobileCheck()) {
        const sidebar = document.getElementById("sidebar")
        const content = document.getElementById("sidebar-content")
        if (sidebar !== null) {
            sidebar.classList.remove('open')
            content.classList.remove('open')
        }

    }
    return true
})

export default router
