<template>

  <!-- Navbar Start -->
  <nav class="navbar navbar-expand bg-secondary navbar-dark sticky-top px-4 py-0">
    <a href="index.html" class="navbar-brand d-flex d-lg-none me-4">
      <h2 class="text-primary mb-0"><i class="fa fa-user-edit"></i></h2>
    </a>
    <a @click="toggleNavbar" class="sidebar-toggler flex-shrink-0">
      <i class="fa fa-bars"></i>
    </a>
    <!--form class="d-none d-md-flex ms-4">
      <input class="form-control bg-dark border-0" type="search" placeholder="Search">
    </form-->
    <div class="navbar-nav align-items-center ms-auto">
      <div class="nav-item">
        <a href="https://discord.gg/guildguardian" class="text-light"><i class="fa-brands fa-discord fs-4 hoverable" style="cursor:pointer;"/></a>
      </div>
      <!--div class="nav-item dropdown">
        <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
          <i class="fa fa-envelope me-lg-2"></i>
          <span class="d-none d-lg-inline-flex">Message</span>
        </a>
        <div class="dropdown-menu dropdown-menu-end bg-secondary border-0 rounded-0 rounded-bottom m-0">
          <a href="#" class="dropdown-item">
            <div class="d-flex align-items-center">
              <img class="rounded-circle" src="img/user.jpg" alt="" style="width: 40px; height: 40px;">
              <div class="ms-2">
                <h6 class="fw-normal mb-0">Jhon send you a message</h6>
                <small>15 minutes ago</small>
              </div>
            </div>
          </a>
          <hr class="dropdown-divider">
          <a href="#" class="dropdown-item">
            <div class="d-flex align-items-center">
              <img class="rounded-circle" src="img/user.jpg" alt="" style="width: 40px; height: 40px;">
              <div class="ms-2">
                <h6 class="fw-normal mb-0">Jhon send you a message</h6>
                <small>15 minutes ago</small>
              </div>
            </div>
          </a>
          <hr class="dropdown-divider">
          <a href="#" class="dropdown-item">
            <div class="d-flex align-items-center">
              <img class="rounded-circle" src="img/user.jpg" alt="" style="width: 40px; height: 40px;">
              <div class="ms-2">
                <h6 class="fw-normal mb-0">Jhon send you a message</h6>
                <small>15 minutes ago</small>
              </div>
            </div>
          </a>
          <hr class="dropdown-divider">
          <a href="#" class="dropdown-item text-center">See all message</a>
        </div>
      </div-->
      <!--div class="nav-item dropdown">
        <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
          <i class="fa fa-bell me-lg-2"></i>
          <span class="d-none d-lg-inline-flex">Notificatin</span>
        </a>
        <div class="dropdown-menu dropdown-menu-end bg-secondary border-0 rounded-0 rounded-bottom m-0">
          <a href="#" class="dropdown-item">
            <h6 class="fw-normal mb-0">Profile updated</h6>
            <small>15 minutes ago</small>
          </a>
          <hr class="dropdown-divider">
          <a href="#" class="dropdown-item">
            <h6 class="fw-normal mb-0">New user added</h6>
            <small>15 minutes ago</small>
          </a>
          <hr class="dropdown-divider">
          <a href="#" class="dropdown-item">
            <h6 class="fw-normal mb-0">Password changed</h6>
            <small>15 minutes ago</small>
          </a>
          <hr class="dropdown-divider">
          <a href="#" class="dropdown-item text-center">See all notifications</a>
        </div>
      </div-->
      <div class="nav-item dropdown">
        <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
          <img class="rounded-circle me-lg-2" :src="$api.getAvatar()" alt="" style="width: 40px; height: 40px;">
          <span class="d-none d-lg-inline-flex">{{ $api.getUsername() }}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-end bg-secondary border-0 rounded-0 rounded-bottom m-0">
          <!--a href="#" class="dropdown-item">My Profile</a>
          <a href="#" class="dropdown-item">Settings</a-->
          <a v-if="canSwitchDiscord" class="dropdown-item" @click="guildSwitchOpen = true">Discord wechseln</a>
          <a @click="logout" class="dropdown-item">Log Out</a>
        </div>
      </div>
    </div>
  </nav>
  <Modal :open="guildSwitchOpen" title="Discord wechseln" id="guild-switch" :remove-buttons="true" @close="guildSwitchOpen = false">
    <div class="d-flex justify-content-around flex-wrap">
      <div v-for="guild of getGuilds()">
        <div @click="selectGuild(guild)" class="d-flex flex-column justify-content-center align-items-center p-2 guild-select" style="max-width: 150px; width: 150px; height: 150px; max-height: 150px;">
          <img v-if="guild.image && guild.image.length > 0" :src="guild.image" width="75" class="rounded-circle">
          <div v-if="!guild.image || guild.image.length === 0" class="circle">{{ getGuildInitials(guild.name) }}</div>
          <span class="mt-2 text-center">{{guild.name}}</span>
        </div>
      </div>
    </div>
  </Modal>
  <!-- Navbar End -->
</template>

<script>
import app from "@/main";
import Modal from "@/components/modal/Modal.vue";

export default {
  components: {Modal},
  data() {
    return {
      canSwitchDiscord: false,
      guilds: [],
      guildSwitchOpen: false,
    }
  },
  methods: {
    getGuilds() {
      return JSON.parse(this.$ls.get('guilds'))
    },
    getGuildInitials(name) {
      if(name === undefined)
        return ''
      let initials = ''
      for (let initial of name.split(' ')) {
        initials += initial[0]
      }
      return initials
    },
    async selectGuild(guild) {
      this.guildSwitchOpen = false
      this.$ls.set('guild', guild.id)
      this.$ls.set('guild_name', guild.name)
      await this.$api.loadMe()
      await this.$api.loadPermissions()
      const features = await this.$api.getGuildConfig()
      if(features != null)
        app.ls.set('features', JSON.stringify(features.data.features))
      this.$ls.set('users', JSON.stringify(await this.$api.getUsers()))
      this.$ls.set('user_cache', this.$ls.get('users'))
      this.$router.push('/')
      window.location.reload()
    },
    toggleNavbar() {
      document.getElementById("sidebar").classList.toggle("open")
      document.getElementById("sidebar-content").classList.toggle('open')
    },
    logout() {
      localStorage.clear()
      this.$router.push('/login')
    }
  },
  mounted() {
    this.canSwitchDiscord = app.ls.get('guilds') !== null
    this.guilds = JSON.parse(app.ls.get('guilds'))
  }
}
</script>

<style>


.guild-select {
  border-radius: 10px;
}

.guild-select:hover {
  cursor: pointer;
  background-color: #343a40;
}

.circle {
  width: 90px;
  border-radius: 50%;
  font-family: roboto, arial;
  color: white;
  line-height: 90px;
  text-align: center;
  background: #313338;

}
</style>