<template>
  <div id="sidebar" class="sidebar pe-4 pb-3">
    <nav class="navbar bg-secondary navbar-dark">
      <router-link to="/" tag="a" class="navbar-brand mx-4 mb-3">
        <h3 class="text-primary">
          <img width="50" height="50" src="/img/logo.png">{{ $api.getCurrentGuildName() }}
        </h3>
      </router-link>
      <div class="d-flex align-items-center ms-4 mb-4">
        <div class="position-relative">
          <img class="rounded-circle" :src="$api.getAvatar()" alt="" style="width: 40px; height: 40px;">
          <div
              class="bg-success rounded-circle border border-2 border-white position-absolute end-0 bottom-0 p-1"></div>
        </div>
        <div class="ms-3">
          <h6 class="mb-0">{{ $api.getUsername() }}</h6>
          <span>{{ $api.getHighestGroup().name }}</span>
        </div>
      </div>
      <div class="navbar-nav w-100">
        <template v-for="nav in navigation" :key="nav.name">
          <template v-if="!nav.permission || $api.hasPermission(nav.permission)">
            <template v-if="!nav.dropdown">
              <template v-if="!nav.feature || $api.getFeatures().includes(nav.feature)">
                <router-link tag="a" :to="nav.to" class="nav-item nav-link" :class="{'active': nav.active}">
                  <i class="fa me-2" :class="nav.icon"></i>
                  {{ nav.name }}
                </router-link>
              </template>
              <template v-else>
                <Tooltip placement="right">
                  <a class="nav-item nav-link missing-feature">
                      <i class="fa me-2 fa-money-bill text-warning"/>
                      {{nav.name}}
                  </a>
                  <template #popper>
                    <a>Dies ist ein kostenpflichtiges Feature.</a>
                  </template>
                </Tooltip>
              </template>
            </template>
            <template v-else>
              <div class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" :class="{'active': nav.active, 'show': nav.active || nav.toggled}" @click="nav.toggled = !nav.toggled" :aria-expanded="nav.toggled || nav.active"><i class="fa me-2" :class="nav.icon"></i>
                  {{ nav.name }}</a>
                <div class="dropdown-menu bg-transparent border-0" :class="{'show': nav.toggled || nav.active}">
                  <template v-for="dropdown in nav.dropdown">
                    <template v-if="!dropdown.permission || $api.hasPermission(dropdown.permission)">
                      <template v-if="!dropdown.feature || $api.getFeatures().includes(dropdown.feature)">
                        <router-link :to="dropdown.to" tag="a" class="dropdown-item" :class="{'active': dropdown.active}">
                          {{ dropdown.name }}
                        </router-link>
                      </template>
                      <template v-else>
                        <Tooltip placement="right">
                          <a class="dropdown-item text-warning">
                            {{dropdown.name}}
                          </a>
                          <template #popper>
                            <a>Dies ist ein kostenpflichtiges Feature.</a>
                          </template>
                        </Tooltip>
                      </template>
                    </template>
                  </template>
                </div>
              </div>
            </template>
          </template>
        </template>
      </div>
    </nav>
  </div>
</template>
<script setup>
import {onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router";
import {Tooltip} from "floating-vue";

const route = useRoute()

const navigation = ref([
  {name: 'Dashboard', to: '/dashboard', icon: 'fa-tachometer-alt', active: false},
  {name: 'Team Manager', to: '/team-manager', icon: 'fa-users-gear', permission: 'team.enter', active: false},
  {name: 'Users', to: '/user', icon: 'fa-users', permission: 'users.enter', active: false},
  {name: 'Channels', to: '/channel', icon: 'fa-layer-group', permission: 'channel.enter', active: false},
  {name: 'Music', to: '/music/general', icon: 'fa-music', permission: 'music.enter', feature: 'music', active: false},
  {name: 'Embed', to: '/embed', icon: 'fa-comment-dots', permission: 'embed.view', active: false},
  {name: 'Custom Bot', to: '/custombot', icon: 'fa-robot', permission: 'custombot.enter', active: false},
  {name: 'Features', to: '/features', icon: 'fa-hammer', active: false, dropdown: [
      {name: 'Apply', to: '/features/apply', active: false, permission: 'system.apply.view'},
      {name: 'Level', to: '/features/level', active: false, permission: 'system.level.view'},
      {name: 'Twitch', to: '/features/twitch', active: false, permission: 'system.twitch.view'},
      {name: 'YouTube', to: '/features/youtube', active: false, permission: 'system.youtube.view'},
      {name: 'Ticket', to: '/features/ticket', active: false, permission: 'system.ticket.view'},
      {name: 'Giveaway', to: '/features/giveaway', active: false, permission: 'system.giveaway.view', feature: 'system.giveaway'},
      {name: 'Autorole', to: '/features/autorole', permission: 'system.autorole.view', feature: 'system.autorole', active: false},
      {name: 'Automessage', to: '/features/automessage', permission: 'system.automessage.view', active: false},
      {name: 'Selfrole', to: '/features/selfrole', permission: 'system.selfrole.view', feature: 'system.selfrole', active: false},
      {name: 'Join to create', to: '/features/jointocreate', permission: 'system.jointocreate.enter', active: false},
      {name: 'Count', to: '/features/count', permission: 'system.count', active: false},
      {name: 'Verify', to: '/features/verify', permission: 'system.verify.view', feature: 'system.verify', active: false},
      {name: 'Roster', to: '/features/roster', permission: 'system.roster', active: false},
    ]
  },
  {
    name: 'Security', to: '/security', icon: 'fa-shield-halved', permission: 'system.security.view', active: false, toggled: false, dropdown: [
      {name: 'Allgemein', to: '/security/general', permission: 'system.security.view', active: false},
      {name: 'Anti Bot', to: '/security/antibot', permission: 'system.security.view', active: false},
      {name: 'Anti Spam', to: '/security/antispam', permission: 'system.security.view', active: false},
    ]
  },
  {
    name: 'System', to: '/system', icon: 'fa-gear', permission: 'system.enter', feature: 'system', active: false, toggled: false, dropdown: [
      {name: 'AutoMod', to: '/system/automod', permission: 'automod.enter', active: false, feature: 'system.automod'},
      {name: 'Log', to: '/system/log', permission: 'system.log.view',feature: 'system.log', active: false},
      {name: 'Bann Liste', to: '/system/banlist', permission: 'system.banlist.view', feature: 'system.banlist', active: false},
      {name: 'Unban', to: '/system/unban', permission: 'system.unban.view', feature: 'system.unban', active: false},
      {name: 'Warns', to: '/system/warns', permission: 'system.warn.view', feature: 'system.warn', active: false},
    ]
  }
])

const updateNav = (r) => {
  const active = navigation._value.find(n => n.active)
  if (active !== undefined) {
    active.active = false
    if (active.dropdown) {
      active.toggled = false
      for (let filterElement of active.dropdown.filter(d => d.active)) {
        filterElement.active = false
      }
    }
  }
  let find = navigation.value.find(n => r.path.startsWith(n.to))
  if (find === undefined)
    return
  find.active = true
  if(find.dropdown === undefined)
    return;
  find = find.dropdown.find(d => r.path.startsWith(d.to))
  if(find === undefined)
    return
  find.active = true
}

onMounted(() => {
  updateNav(useRoute())
})

watch(() => route.path, () => {
  updateNav(route)
});

</script>

<style>
.missing-feature:hover {
  border-color: #ffc107 !important;
}
</style>